#hero {
  text-align: center;
  min-height: 620px;
  height: 100vh;
  z-index: 8;
  max-height: 820px;
  border-top: 4px solid #37b0e7;
  padding: 5em 0 0;
  position: relative;
}

#hero .titles {
  z-index: 150;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#hero .titles h1 {
  color: #277a9f;
  margin: 0 auto 1.5rem;
  font-size: 2.75rem;
}

#hero .titles h1:after {
  border: 1px solid #fff;
}

#hero .titles h2 {
  color: #1890c6;
  max-width: 750px;
  background-color: #ffffff06;
  margin: 0 auto 7.5rem;
  padding: 0 1.5rem;
  font-family: Work Sans, Helvetica Neue, Helvetica, Arial, Geneva, sans-serif;
  font-size: 1.5rem;
  line-height: 1.525;
}

#hero .titles .button {
  margin-top: 3.5em;
}

#code {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

#code pre {
  letter-spacing: -.02em;
  color: #7e949c;
  min-height: 350px;
  max-height: 360px;
  border-radius: 1rem;
  margin: 1em 0;
  padding: 1rem 1rem 0;
  font-size: .85rem;
  line-height: 1.4;
  overflow-y: scroll;
}

#code pre em {
  opacity: .7;
  font-style: normal;
}

#code ul.slides {
  height: 450px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
  overflow: hidden;
}

#code .slides * {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout: none;
}

#code ul.slides input {
  display: none;
}

#code .slide-container {
  display: block;
}

#code .slide-image {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all .7s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
}

#code .slide-image pre {
  width: auto;
  min-width: 100%;
  height: 100%;
}

#code .carousel-controls {
  z-index: 999;
  color: #fff;
  font-size: 100px;
  line-height: 450px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#code .carousel-controls label {
  opacity: 0;
  cursor: pointer;
  padding: 0 20px;
  transition: opacity .2s;
  display: none;
  position: absolute;
}

#code .slide-image:hover + .carousel-controls label {
  opacity: .5;
}

#code .carousel-controls label:hover {
  opacity: 1;
}

#code .carousel-controls .prev-slide {
  width: 49%;
  text-align: left;
  left: 0;
}

#code .carousel-controls .next-slide {
  width: 49%;
  text-align: right;
  right: 0;
}

#code .carousel-dots {
  z-index: 999;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#code .carousel-dots .carousel-dot {
  width: 12px;
  height: 12px;
  opacity: .5;
  background-color: #7e949c;
  border-radius: 50%;
  margin: 10px;
  display: inline-block;
}

#code input:checked + .slide-container .slide-image {
  opacity: 1;
  transition: opacity 1s ease-in-out;
  transform: scale(1);
}

#code input:checked + .slide-container .carousel-controls label {
  display: block;
}

#code input#img-1:checked ~ .carousel-dots label#img-dot-1, #code input#img-2:checked ~ .carousel-dots label#img-dot-2, #code input#img-3:checked ~ .carousel-dots label#img-dot-3, #code input#img-4:checked ~ .carousel-dots label#img-dot-4, #code input#img-5:checked ~ .carousel-dots label#img-dot-5, #code input#img-6:checked ~ .carousel-dots label#img-dot-6 {
  opacity: 1;
}

#code input:checked + .slide-container .nav label {
  display: block;
}

#code h4 {
  margin: 182px 0 1rem;
}

#code .code-examples p {
  margin-top: 250px;
}

#code ul.slide-triggers {
  min-width: 32%;
  margin: -5px 0 0;
  position: absolute;
  top: 225px;
  left: 54%;
}

#code ul.slide-triggers li.slide-trigger {
  margin: 5px 0;
  padding: 0;
}

#code ul.slide-triggers li.slide-trigger label {
  color: #7e949c;
  cursor: pointer;
  z-index: 250;
  background-color: #f6f9fa;
  border-radius: 3px;
  padding: .3rem 1rem .3rem 3.2rem;
  line-height: 2.2;
  display: block;
  position: relative;
}

#code ul.slide-triggers li.slide-trigger label img {
  min-width: auto;
  position: absolute;
  top: .9rem;
  left: 1.25rem;
}

#code ul.slide-triggers li.slide-trigger label:after {
  display: none;
}

#code ul.slide-triggers li.slide-trigger label:hover {
  background-color: #eff5f6;
}

#code ul.slide-triggers li.slide-trigger label.active {
  color: #37b0e7;
  background-color: #eff5f6;
}

#code ul.slide-triggers li.slide-trigger label.active:hover {
  background-color: #eff5f6;
}

#code img.arrow {
  min-width: 14px;
  width: 14px;
  margin: -2px 0 0 5px;
  position: relative;
}

#code .button.primary {
  margin-top: 2.5rem;
}

#kubernetes {
  padding-top: 2.67rem;
}

#kubernetes h2, #kubernetes p {
  margin-left: 1.5rem;
}

#kubernetes p {
  max-width: 28.5em;
  padding-right: 10%;
}

#kubernetes .cli {
  width: 175%;
  z-index: 850;
  margin-top: 2rem;
  position: relative;
}

#kubernetes .cli:before {
  width: 100%;
  height: 2rem;
  content: " ";
  z-index: -10;
  background: #b8c4c8;
  border: 1px solid #7e949c;
  border-radius: .67em .67em 0 0;
  display: block;
  position: absolute;
  top: -1.5rem;
}

#kubernetes .cli pre {
  color: #bed45b;
  background: #383948;
  border-radius: 0 0 .67em .67em;
  padding: 15px 1.5rem 0;
  font-size: 14px;
}

#kubernetes .cli pre em {
  color: #7e949c;
  font-style: normal;
}

@media screen and (max-width: 768px) {
  .pipe-wrapper {
    display: none !important;
  }

  .navbar {
    z-index: 1100;
    min-height: 12rem !important;
    padding-top: 0 !important;
  }

  .navbar .navbar-brand img {
    max-height: 5rem !important;
  }

  .navbar .navbar-burger {
    margin-top: -7rem !important;
  }

  #intro {
    min-height: 65vw;
    margin-bottom: -12.5vw;
    padding-top: 22.5vw;
    position: relative;
  }

  #intro h1 {
    padding: 0 10vw;
    font-size: 5vw !important;
  }

  #intro, #kubernetes, #code, #community, #v2 {
    width: 80% !important;
    height: auto !important;
    margin: 2rem 10% !important;
    padding: 5rem 0 !important;
    position: relative !important;
    inset: auto !important;
  }

  #code ul.slide-triggers {
    margin: -5px 0 0;
    min-width: 100% !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
  }

  #code h4 {
    display: none !important;
  }

  #code p {
    margin-top: 0 !important;
  }

  .retrobox.retrobox-v2 {
    margin: 0 !important;
  }
}

@media screen and (min-width: 769px) {
  .pipe-wrapper {
    margin-top: -37.5vw;
    min-height: 3550px !important;
  }

  .navbar {
    z-index: 1100;
    min-height: 10rem !important;
    padding-top: 0 !important;
  }

  .navbar .navbar-burger {
    margin-top: -7rem !important;
  }

  #intro {
    min-height: 65vw;
    margin-bottom: -12.5vw;
    position: relative;
    padding-top: 15vw !important;
  }

  #intro h1 {
    max-width: 50vw;
    padding: 0;
    font-size: 5vw !important;
  }

  #code {
    top: 680px !important;
    left: 5vw !important;
  }

  #kubernetes {
    top: 1540px !important;
  }

  #video {
    width: 100vw !important;
    top: 2215px !important;
  }

  #community {
    top: 2140px !important;
  }

  #v2 {
    width: 120% !important;
    top: 2750px !important;
    left: 0 !important;
    right: 0 !important;
  }
}

@media screen and (min-width: 1024px) {
  body {
    min-height: 350vh;
  }

  .pipe-wrapper {
    min-height: 3850px !important;
  }

  #intro {
    min-height: 668px !important;
  }

  #intro h1 {
    font-size: 3.5rem !important;
  }

  #code {
    top: 962px !important;
  }

  #kubernetes {
    top: 1880px !important;
  }

  #video {
    top: 2610px !important;
  }

  #community {
    top: 2520px !important;
  }

  #v2 {
    top: 3120px !important;
  }
}

@media screen and (min-width: 1216px) {
  .pipe-wrapper {
    min-height: 1850px !important;
  }
}

@media screen and (min-width: 1408px) {
  .pipe-wrapper {
    min-height: 1850px !important;
  }
}

body {
  color: #7e949c;
  background: #ffdee9 linear-gradient(0deg, #9aaacd 0%, #e4ece8 50%, #eef6f6 80%, #f2f2f2 100%);
  border-top: 4px solid #37b0e7;
  font-family: Work Sans, Helvetica Neue, Helvetica, Arial, Geneva, sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}

.row.fullwidth {
  max-width: 100% !important;
}

.row.fullheight {
  min-height: 100%;
  z-index: 150;
  position: relative;
}

h1, h2, h3 {
  font-family: Work Sans, Helvetica Neue, Helvetica, Arial, Geneva, sans-serif;
}

p a, li a, small a {
  color: #37b0e7;
  transition: all .3s ease-in-out;
  position: relative;
}

p a:after, li a:after, small a:after {
  width: 10%;
  height: 3px;
  content: " ";
  opacity: 0;
  background: #37b0e7;
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
}

p a:hover:after, li a:hover:after, small a:hover:after {
  width: 100%;
  opacity: 1;
  left: 0;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.navbar-burger {
  z-index: 1301;
  position: absolute;
}

.navbar {
  background: none;
  padding-top: 2rem;
}

.navbar .navbar-brand {
  margin-left: 12.5vw;
}

.navbar .navbar-brand img {
  min-height: 130px;
}

.navbar .navbar-end {
  margin-right: 12.5vw;
}

.navbar li.navbar-item a {
  color: #18104e;
  margin-left: 1.67vw;
  font-size: 1.25rem;
}

.row {
  max-width: 72.5rem;
}

.button {
  letter-spacing: .02em;
  color: #fff;
  background: #37b0e7;
  border: none;
  border-radius: 5em;
  margin: 1em auto;
  padding: 1.333rem 2.825rem;
  font-family: Work Sans, Helvetica Neue, Helvetica, Arial, Geneva, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  transition: all .3s ease-in-out;
  box-shadow: 0 2px 14px #00000005;
}

.button:hover {
  color: #fff;
  opacity: .95;
  background: #1890c6;
}

.content h2 {
  color: #37b0e7;
  margin-bottom: .5em;
  font-family: Work Sans, Helvetica Neue, Helvetica, Arial, Geneva, sans-serif;
  font-size: 2rem;
}

.content h4 {
  color: #7e949c;
  font-family: Work Sans, Helvetica Neue, Helvetica, Arial, Geneva, sans-serif;
  font-size: 1rem;
}

.content p, .content li {
  color: #647b83;
  padding-right: 5%;
  font-size: 1rem;
  line-height: 1.7;
}

.content .center {
  text-align: center;
}

.content img {
  min-width: 90%;
  height: auto;
  margin-right: -5%;
}

.content code, .content pre {
  color: #7e949c;
  letter-spacing: .05em;
  background: #eef4f5;
  border: none;
  border-radius: .25em;
  outline: none;
  padding-left: .5em;
  padding-right: .5em;
  font-size: 1em;
}

.content ul {
  padding: 1em 0;
}

.content ul li {
  margin-bottom: 1em;
  padding-right: 1.75em;
  list-style-type: none;
  position: relative;
}

.content ul.fancy {
  padding-left: 0;
}

.content ul.fancy li {
  background: url("asterisk-01.48e1d83a.svg") 0 4px no-repeat;
  padding-left: 2.5rem;
}

.content ul.fancy li:nth-of-type(2n+1) {
  background: url("asterisk-02.d8ead7fb.svg") 0 4px no-repeat;
}

.feature-main, .feature-complexity, .feature-language {
  min-height: 600px;
  padding-top: 8.5rem;
  padding-bottom: 6.5rem;
  display: block;
  position: relative;
}

.feature-main h3, .feature-complexity h3, .feature-language h3 {
  color: #1998d2;
  letter-spacing: .02em;
  margin: 0 0 1.25rem;
  font-size: 2.75rem;
  line-height: 1.15;
}

.feature-complexity {
  background: linear-gradient(30deg, #fff 0%, #e5ebec 100%);
}

.feature-language {
  color: #fff;
  background: linear-gradient(160deg, #2693b4 0%, #00b4eb 100%);
}

.feature-language img {
  margin: 3rem 0 0;
}

.feature-language h3, .feature-language p {
  color: #fff;
}

.feature-language h3 a, .feature-language p a {
  color: #fff;
  border-bottom: 2px dashed #fff6;
}

footer {
  color: #7e949c;
  background: linear-gradient(110deg, #fff 20%, #f0f0f0);
  padding: 2em 0;
}

footer .footerwrap {
  margin: 1rem auto 3rem;
  max-width: 85vw !important;
}

footer h4 {
  color: #18104e;
  margin: 2rem 0 0 -2vw;
  font-size: 1.25rem;
  font-weight: 600;
}

footer .brigade-logo img {
  max-width: 6vw;
}

footer .brigade-logo strong {
  width: 8vw;
  min-height: 8rem;
  background: url("favicon.944cd3d0.png") 0 0 / contain no-repeat;
  margin-left: -1rem;
  display: inline-block;
  position: relative;
}

footer .brigade-logo:last-of-type {
  max-width: 5.85vw;
  margin: -.25vw 0 0;
}

footer ul {
  margin: .75rem 0 2rem -2vw;
  list-style: none;
}

footer ul a {
  color: #121562;
  margin-bottom: .25rem;
  padding: .25em 0 0;
  line-height: 1.35;
  display: inline-block;
}

footer .cncf {
  margin-bottom: 5rem;
}

footer .cncf p {
  margin: 0 0 1.5rem;
  font-size: 1.225rem;
}

footer .cncf p:last-of-type {
  margin-bottom: 0;
}

footer .cncf img {
  max-width: 11.25rem;
}

#whats-new-in-v2 {
  padding-top: 4rem;
  padding-bottom: 7.5rem;
}

.row.cncf-wrap {
  max-width: 100% !important;
}

#pipes {
  opacity: .175;
}

.pipe-wrapper {
  min-height: 200vh;
  margin-top: -20.5vw;
}

path {
  stroke-dasharray: 7150;
  stroke-dashoffset: 7150px;
}

svg {
  width: 96vw;
  margin: 5vw 0 15vw 5vw;
}

#intro {
  min-height: 65vw;
  margin-bottom: -12.5vw;
  padding-top: 22.5vw;
  position: relative;
}

#intro:after {
  content: " ";
  z-index: -1;
  background: url("skytop.5517a1b8.svg") 0 0 / cover no-repeat;
  display: inline-block;
  position: absolute;
  inset: -10rem 0 0;
}

#intro h1 {
  width: 100%;
  color: #18104e;
  font-family: Work Sans, Helvetica Neue, Helvetica, Arial, Geneva, sans-serif;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
  position: absolute;
}

#intro .dots em {
  width: 1rem;
  height: 1rem;
  background: #fff;
  border-radius: .5rem;
  margin: auto;
  transition: all .7s;
  animation: slideRight 3s linear infinite;
  position: absolute;
  top: 12.5vw;
  left: 25vw;
}

#intro .dots em:first-of-type {
  animation: slideRight 3s linear infinite;
  top: 12.5vw;
  left: -2vw;
}

#intro .dots em:nth-child(2) {
  animation: slideRight 4s linear infinite;
  top: 34vw;
  left: -2vw;
}

#intro .dots em:nth-child(3) {
  animation: slideLeft 1.8s linear infinite;
  top: 15vw;
  left: -2vw;
}

#intro .dots em:nth-child(4) {
  animation: slideLeft 2.33s linear infinite;
  top: 25vw;
  left: -2vw;
}

#intro .dots em:nth-child(5) {
  animation: slideRight 2s linear infinite;
  top: 22.5vw;
  left: -2vw;
}

#intro .dots em:last-of-type {
  animation: slideLeft 5s linear infinite;
  bottom: -1vw;
  left: -2vw;
}

@keyframes slideRight {
  from {
    background: #fff;
    left: -2vw;
  }

  to {
    background: #1aac9d;
    left: 102vw;
  }
}

@keyframes slideLeft {
  from {
    background: #fff;
    right: -2vw;
  }

  to {
    background: #1aac9d;
    right: 102vw;
  }
}

.retrobox {
  z-index: 1200;
  background: #fffc;
  border-radius: 1.5rem;
  padding: 1rem;
  transition: all .3s ease-in-out;
  position: relative;
}

.retrobox:hover {
  box-shadow: 0 2px 10px #0000001f;
}

.retrobox.retrobox-v2 {
  margin-left: 10vw;
  padding: 2rem 3rem 1rem;
}

#code {
  width: 90vw;
  height: 800px;
  z-index: 1140;
  position: absolute;
  top: 87vh;
  left: 10vw;
  right: 5vw;
}

#code .code-examples {
  padding-left: 3.5rem;
}

#kubernetes {
  width: 95vw;
  height: 500px;
  z-index: 1200;
  position: absolute;
  top: 2280px;
  left: 10vw;
  right: 5vw;
}

#video {
  width: 92vw;
  z-index: 400;
  position: absolute;
  top: 205vh;
  left: 10vw;
  right: 10vw;
}

#community {
  width: 90vw;
  z-index: 400;
  position: absolute;
  top: 200vh;
  left: 17vw;
  right: 10vw;
}

#community .retrobox {
  padding: 3rem 2vw;
}

#v2 {
  width: 80vw;
  z-index: 500;
  position: absolute;
  top: 250vh;
  left: 10vw;
  right: 10vw;
}

/*# sourceMappingURL=index.0c4423a4.css.map */
