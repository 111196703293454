#kubernetes {
  padding-top: 2.67rem;
  // padding-bottom: 12rem;

  h2, p {
    margin-left: 1.5rem;
  }

  p {
    padding-right: 10%;
    max-width: 28.5em;
  }

  .cli {
    // padding: 0.1em;
    // margin-top: 84px;
    width: 175%;
    margin-top: 2rem;
    // margin-left: -12%;
    position: relative;
    z-index: 850;
    
    &:before {
      width: 100%;
      height: 2rem;
      top: -1.5rem;
      background: lighten($mid, 20%);
      border: 1px solid $mid;
      border-radius: 0.67em 0.67em 0 0;
      content: " ";
      position: absolute;
      display: block;
      z-index: -10;
    }

    pre {
      background: #383948;
      padding: 15px 1.5rem 0;
      font-size: 14px;
      color: darken($yellow, 27.5%);
      border-radius: 0 0 0.67em 0.67em;

      em {
        font-style: normal;
        color: $mid;
      }
    }
  }
}
