/*  bulma css breakpoints:
    mobile: up to 768px
    tablet: from 769px
    desktop: from 1024px
    widescreen: from 1216px
    fullhd: from 1408px
*/


/* mobile and under */
@media screen and (max-width: 768px) {
    .pipe-wrapper {
        display: none !important;
    }

    .navbar {
        z-index: 1100;
        padding-top: 0 !important;
        min-height: 12rem !important;

        .navbar-brand {
            img {
                max-height: 5rem !important;
            }
        }

        .navbar-burger {
            margin-top: -7rem !important;
        }
    }

    #intro {
        padding-top: 22.5vw;
        position: relative;
        min-height: 65vw;
        margin-bottom: -12.5vw;

        h1 {
            font-size: 5vw !important;
            padding: 0 10vw;
        }
    }

    #intro, #kubernetes, #code, #community, #v2 {
        position: relative !important;
        top: auto !important;
        bottom: auto !important;
        left: auto !important;
        right: auto !important;
        width: 80% !important;
        margin: 2rem 10% !important;
        padding: 5rem 0 !important;
        height: auto !important;
    }

    #code {
        ul.slide-triggers {
            margin: -5px 0 0;
            position: relative !important;
            top: auto !important;
            left: auto !important;
            min-width: 100% !important;
        }

        h4 {
            display: none !important;
        }

        p {
            margin-top: 0 !important;
        }
    }

    .retrobox.retrobox-v2 {
        margin: 0 !important;
    }
}

/* tablet and up */
@media screen and (min-width: 769px) {
    .pipe-wrapper {
        margin-top: -37.5vw;
        min-height: 3550px !important;
    }

    .navbar {
        z-index: 1100;
        padding-top: 0 !important;
        min-height: 10rem !important;

        .navbar-burger {
            margin-top: -7rem !important;
        }
    }

    #intro {
        padding-top: 15vw !important;
        position: relative;
        min-height: 65vw;
        margin-bottom: -12.5vw;
    
        h1 {
            font-size: 5vw !important;
            padding: 0;
            max-width: 50vw;
        }
    }

    #code {
        top: 680px !important;
        left: 5vw !important;
    }

    #kubernetes {
        top: 1540px !important;
    }

    #video {
        top: 2215px !important;
        width: 100vw !important;
    }

    #community {
        top: 2140px !important;
    }

    #v2 {
        left: 0 !important;
        right: 0 !important;
        top: 2750px !important;
        width: 120% !important;
    }
}

/* desktop and up */
@media screen and (min-width: 1024px) {
    body {
        min-height: 350vh;
    }
    
    .pipe-wrapper {
        min-height: 3850px !important;
    }

    #intro {
        min-height: 668px !important;
        
        h1 {
            font-size: 3.5rem !important;
        }
    }

    #code {
        top: 962px !important;
    }

    #kubernetes {
        top: 1880px !important;
    }

    #video {
        top: 2610px !important;
    }

    #community {
        top: 2520px !important;
    }

    #v2 {
        top: 3120px !important;
    }
}

/* widescreen and up */
@media screen and (min-width: 1216px) {
    .pipe-wrapper {
        min-height: 1850px !important;
    }

}

/* fullhd and up */
@media screen and (min-width: 1408px) {
    .pipe-wrapper {
        min-height: 1850px !important;
    }

}
