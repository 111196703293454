// Import Brigade Styles
// ---------------------------------------
// base framework is bulma css (loaded in head via cdn)
// base font-family is work sans (loaded in head via cdn)
@import "brigade-settings.scss";
@import "brigade-type.scss";
//
// Sections
@import "brigade-landing.scss";
@import "brigade-code.scss";
@import "brigade-kubernetes.scss";
//
@import "brigade-media.scss";


// Core Layout
// ---------------------------------------
//

html,
body {
  // height: 100%;
}

body {
  background: $light;
  font-family: $work;
  color: $mid;
  font-size: 16px;
  overflow-x: hidden;
  background: white;
  border-top: 4px solid #37b0e7;
  background-color: #FFDEE9;
  background-image: linear-gradient(0deg, #9AAACD 0%, #E4ECE8 50%, #EEF6F6 80%, #f2f2f2 100%);
}

.row.fullwidth {
  max-width: 100% !important;
}

.row.fullheight {
  min-height: 100%;
  position: relative;
  z-index: 150;
}

h1, h2, h3 {
  font-family: $work;
}

p,
li,
small {
  a {
    color: $bluem;
    position: relative;
    @include transition;

    &:after {
      width: 10%;
      height: 3px;
      position: absolute;
      bottom: -2px;
      left: 50%;
      display: block;
      content: " ";
      background: $bluem;
      opacity: 0;
      @include transition;
    }

    &:hover:after {
      width: 100%;
      left: 0;
      opacity: 1;
    }
  }
}

.vertical-center {
  @include vertical-align;
}

.navbar-burger {
  position: absolute;
  z-index: 1301;
}

.navbar {
  background: transparent;
  padding-top: 2rem;

  .navbar-brand {
    margin-left: 12.5vw;

    img {
      min-height: 130px;
    }
  }

  .navbar-end {
    margin-right: 12.5vw;
  }

  li.navbar-item {
    a {
      color: #18104E;
      font-size: 1.25rem;
      margin-left: 1.67vw;
    }
  }
}

.row {
  max-width: 72.5rem;
}

.button {
  margin: 1em auto;
  background: $bluem;
  font-family: $work;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 0.02em;
  padding: 1.333rem 2.825rem;
  color: white;
  border-radius: 5em;
  border: none;
  @include transition;
  @include box-shadow(0,2px,14px,rgba(0,0,0,0.02));

  &:hover {
    background: darken($bluem, 12.5%);
    color: white;
    opacity: 0.95;
  }
}

.content {
  h1 {

  }

  h2 {
    color: $bluem;
    font-size: 2rem;
    margin-bottom: 0.5em;
    font-family: $work;
  }

  h4 {
    color: $mid;
    font-family: $work;
    font-size: 1rem;
  }

  p,
  li {
    font-size: 1rem;
    line-height: 1.7;
    padding-right: 5%;
    color: darken($mid, 10%);
  }

  .center {
    text-align: center;
  }

  img {
    min-width: 90%;
    height: auto;
    margin-right: -5%;
  }

  code,
  pre {
    background: darken($light, 2.5%);
    color: $mid;
    border: none;
    outline: none;
    font-size: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 0.25em;
    letter-spacing: 0.05em;
  }

  pre {
    // padding: 0.5em;
  }

  ul {
    padding: 1em 0;

    li {
      margin-bottom: 1em;
      padding-right: 1.75em;
      list-style-type: none;
      position: relative;
    }

    &.fancy {
      padding-left: 0;
      li {
        background: url(../images/asterisk-01.svg) 0 4px no-repeat;
        padding-left: 2.5rem;

        &:nth-of-type(odd) {
          background: url(../images/asterisk-02.svg) 0 4px no-repeat;
        }
      }
    }
  }
}

.feature-main,
.feature-complexity,
.feature-language {
  min-height: 600px;
  display: block;
  position: relative;
  padding-top: 8.5rem;
  padding-bottom: 6.5rem;

  h3 {
    color: darken($bluem, 10%);
    font-size: 2.75rem;
    letter-spacing: 0.02em;
    margin: 0 0 1.25rem;
    line-height: 1.15;
  }
}

.feature-complexity {
  background: linear-gradient(30deg, #ffffff 0%, #E5EBEC 100%);
}

.feature-language {
  background: linear-gradient(160deg, #2693B4 0%, #00B4EB 100%);
  color: white;

  img {
    margin: 3rem 0 0 0;
  }

  h3,
  p {
    color: white;

    a {
      color: white;
      border-bottom: 2px dashed rgba(255,255,255,0.4);
    }
  }
}

footer {
  background: -webkit-linear-gradient(110deg, white 20%, #f0f0f0);
  background: -o-linear-gradient(110deg, white 20%, #f0f0f0);
  background: -moz-linear-gradient(110deg, white 20%, #f0f0f0);
  background: linear-gradient(110deg, white 20%, #f0f0f0);
  color: $mid;
  padding: 2em 0 2em;

  .footerwrap {
    max-width: 85vw !important;
    margin: 1rem auto 3rem;
  }

  h4 {
    font-weight: 600;
    color: #18104E;
    margin: 2rem 0 0 -2vw;
    font-size: 1.25rem;
  }
  .brigade-logo {
    img {
      max-width: 6vw;
    }

    strong {
      background: url(../../assets/images/favicon.png) no-repeat 0 0;
      background-size: contain;
      width: 8vw;
      margin-left: -1rem;
      min-height: 8rem;
      display: inline-block;
      position: relative;
    }

    &:last-of-type {
      margin: -0.25vw 0 0;
      max-width: 5.85vw;
    }
  }

  ul {
    list-style: none;
    margin: 0.75rem 0 2rem -2vw;

    a {
      padding: 0.25em 0 0;
      line-height: 1.35;
      margin-bottom: 0.25rem;
      display: inline-block;
      color: darken(#1A1E8D, 10%);
    }
  }

  .cncf {
    margin-bottom: 5rem;
    p {
      margin: 0 0 1.5rem;
      font-size: 1.225rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    img {
      max-width: 11.25rem;
    }
  }
}

#whats-new-in-v2 {
  padding-top: 4rem;
  padding-bottom: 7.5rem;
}

.row.cncf-wrap {
  max-width: 100% !important;
}


// new design elements

#pipes {
  opacity: 0.175;
}

.pipe-wrapper {
  margin-top: -20.5vw;
  min-height: 200vh;
}

path {
  stroke-dasharray: 7150;
  stroke-dashoffset: 7150;
}

svg {
  width: 96vw;
  margin: 5vw 0 15vw 5vw;
}


#intro {
  padding-top: 22.5vw;
  position: relative;
  min-height: 65vw;
  margin-bottom: -12.5vw;

  &:after {
    background: url(../../assets/images/skytop.svg) no-repeat 0% 0%;
    background-size: cover;
    display: inline-block;
    content: " ";
    position: absolute;
    top: -10rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  h1 {
    font-family: $work;
    font-weight: 400;
    font-size: 3rem;
    line-height: 1.2;
    width: 100%;
    color: #18104E;
    position: absolute;
  }


  .dots em {
    background: white;
    width: 1rem;
    height: 1rem;
    margin: auto;
    border-radius: 0.5rem;
    position: absolute;
    top: 12.5vw;
    left: 25vw;
    animation: slideRight 3s linear infinite;
    transition: all 0.7s ease;

    &:first-of-type {
      top: 12.5vw;
      left: -2vw;
      animation: slideRight 3s linear infinite;
    }

    &:nth-child(2) {
      top: 34vw;
      left: -2vw;
      animation: slideRight 4s linear infinite;
    }

    &:nth-child(3) {
      top: 15vw;
      left: -2vw;
      animation: slideLeft 1.8s linear infinite;
    }

    &:nth-child(4) {
      top: 50vw;
      left: -2vw;
      animation: slideRight 3.67s linear infinite;
    }

    &:nth-child(4) {
      top: 25vw;
      left: -2vw;
      animation-delay: 1s;
      animation: slideLeft 2.33s linear infinite;
    }

    &:nth-child(5) {
      top: 22.5vw;
      left: -2vw;
      animation-delay: 1.25s;
      animation: slideRight 2s linear infinite;
    }

    &:last-of-type {
      bottom: -1vw;
      left: -2vw;
      animation: slideLeft 5s linear infinite;
    }
  }

  @keyframes slideRight {
    from {
      left: -2vw;
      background: white;
    }

    to {
      left: 102vw;
      background: #1AAC9D;
    }
  }

  @keyframes slideLeft {
    from {
      right: -2vw;
      background: white;
    }

    to {
      right: 102vw;
      background: #1AAC9D;
    }
  }

}

.retrobox {
  background: rgba(255,255,255,0.8);
  padding: 1rem;
  position: relative;
  z-index: 1200;
  border-radius: 1.5rem;
  @include transition;

  &:hover {
    box-shadow: 0 2px 10px rgba(0,0,0,0.12);
  }

  &.retrobox-v2 {
    padding: 2rem 3rem 1rem;
    margin-left: 10vw;
  }
}

#code {
  position: absolute;
  top: 87vh;
  left: 10vw;
  right: 5vw;
  width: 90vw;
  height: 800px;
  z-index: 1140;

  .code-examples {
    padding-left: 3.5rem;
  }
}

#kubernetes {
  position: absolute;
  left: 10vw;
  right: 5vw;
  width: 95vw;
  height: 500px;
  top: 2280px;
  z-index: 1200;
}

#video {
  position: absolute;
  left: 10vw;
  right: 10vw;
  top: 205vh;
  width: 92vw;
  z-index: 400;
}

#community {
  position: absolute;
  left: 17vw;
  right: 10vw;
  top: 200vh;
  width: 90vw;
  z-index: 400;

  .retrobox {
    padding: 3rem 2vw;
  }
}

#v2 {
  position: absolute;
  left: 10vw;
  right: 10vw;
  top: 250vh;
  width: 80vw;
  z-index: 500;
}

/* refer to brigade-media.scss for the layout positioning adustments per breakpoint */
