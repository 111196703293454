#code {
  padding-top: 5rem;
  padding-bottom: 5rem;

  pre {
    font-size: 0.85rem;
    padding: 1rem 1rem 0;
    border-radius: 1rem;
    margin: 1em 0;
    letter-spacing: -0.02em;
    color: $mid;
    min-height: 350px;
    max-height: 360px;
    overflow-y: scroll;
    line-height: 1.4;

    em {
      opacity: 0.7;
      font-style: normal;
    }
  }

  .carousel {
    // overflow: hidden;
  }

  ul.slides {
      display: block;
      position: relative;
      height: 450px;
      margin: 0;
      padding: 0;
      overflow: hidden;
      list-style: none;
  }

  .slides * {
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
  }

  ul.slides input {
      display: none;
  }

  .slide-container {
      display: block;
  }

  .slide-image {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      opacity: 0;
      transition: all .7s ease-in-out;
  }

  .slide-image pre {
      width: auto;
      min-width: 100%;
      height: 100%;
  }

  .carousel-controls {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      font-size: 100px;
      line-height: 450px;
      color: #fff;
  }

  .carousel-controls label {
      display: none;
      position: absolute;
      padding: 0 20px;
      opacity: 0;
      transition: opacity .2s;
      cursor: pointer;
  }

  .slide-image:hover + .carousel-controls label{
      opacity: 0.5;
  }

  .carousel-controls label:hover {
      opacity: 1;
  }

  .carousel-controls .prev-slide {
      width: 49%;
      text-align: left;
      left: 0;
  }

  .carousel-controls .next-slide {
      width: 49%;
      text-align: right;
      right: 0;
  }

  .carousel-dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      text-align: center;
  }

  .carousel-dots .carousel-dot {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $mid;
      opacity: 0.5;
      margin: 10px;
  }

  input:checked + .slide-container .slide-image {
      opacity: 1;
      transform: scale(1);
      transition: opacity 1s ease-in-out;
  }

  input:checked + .slide-container .carousel-controls label {
      display: block; 
  }

  input#img-1:checked ~ .carousel-dots label#img-dot-1,
  input#img-2:checked ~ .carousel-dots label#img-dot-2,
  input#img-3:checked ~ .carousel-dots label#img-dot-3,
  input#img-4:checked ~ .carousel-dots label#img-dot-4,
  input#img-5:checked ~ .carousel-dots label#img-dot-5,
  input#img-6:checked ~ .carousel-dots label#img-dot-6 {
    opacity: 1;
  }

  input:checked + .slide-container .nav label { display: block; }

  h4 {
    margin: 182px 0 1rem;
  }

  .code-examples {

    p {
      margin-top: 250px;
    }

  }

  ul.slide-triggers {
    margin: -5px 0 0;
    position: absolute;
    top: 225px;
    left: 54%;
    min-width: 32%;

    li.slide-trigger {
      padding: 0;
      margin: 5px 0;

      label {
        padding: 0.3rem 1rem 0.3rem 3.2rem;
        background-color: $light;
        line-height: 2.2;
        display: block;
        border-radius: 3px;
        color: $mid;
        cursor: pointer;
        position: relative;
        z-index: 250;
        
        img {
          min-width: auto;
          position: absolute;
          left: 1.25rem;
          top: 0.9rem;
        }

        &:after {
          display: none;
        }

        &:hover {
          background-color: darken($light, 2%);
        }

        &.active {
          color: $bluem;
          background-color: darken($light, 2%);

          &:hover {
            background-color: darken($light, 2%);
          }
        }
      }
    }
  }

  img.arrow {
    min-width: 14px;
    width: 14px;
    position: relative;
    margin: -2px 0 0 5px;
  }

  .button.primary {
    margin-top: 2.5rem;
  }
}
