// Homepage Intro Hero
// ---------------------------------------
//
#hero {
  text-align: center;
  padding: 5em 0 0;
  min-height: 620px;
  height: 100vh;
  position: relative;
  z-index: 8;
  border-top: 4px solid $bluem;
  max-height: 820px;

  .titles {
    z-index: 150;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    h1 {
      color: $blued;
      font-size: 2.75rem;
      margin: 0 auto 1.5rem;

      &:after {
        border: 1px solid white;
      }
    }

    h2 {
      color: darken($bluem, 12.5%);
      font-size: 1rem;
      background-color: rgba(255,255,255,0.025);
      max-width: 750px;
      padding: 0 1.5rem;
      margin: 0rem auto 7.5rem;
      line-height: 1.525;
      font-family: $work;
      font-size: 1.5rem;
    }

    .button {
      margin-top: 3.5em;
    }
  }
}
